// input[type="checkbox"] {
//   transform: scale(3);
// }

.body {
  font-family: "B612", sans-serif;
}

.wizard-page {
  display: block;
}

.frozen {
  order: -1;
  position: relative;
  left: 0;
  background: pink;
  transition: left 0.2s;
  margin-right: 6px;
}

// .gear-group {
//   position: absolute;
// }

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(75,210,178, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
  border-color: rgb(75, 210, 178);
}

.container {
  max-width: 90%;
}

.modal-dialog {
  max-width: 80%;
}

.modal-linkedin-login {
  width: 20%;
}

.multiselect {
  width: 100%;
}

.wideselect {
  width: 100%;
}

.smartselect {
  flex: 1;
  margin-left: 15px;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.slide-pane {
  display: flex;
  flex-direction: column;
  background: #f4f6f8;
  min-width: 100px;
  height: 100%;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s;
  will-change: transform;
}
.slide-pane:focus {
  outline-style: none;
}
.slide-pane_from_right {
  margin-left: auto;
  transform: translateX(100%);
}
.slide-pane_from_right.ReactModal__Content--after-open {
  transform: translateX(0%);
}
.slide-pane_from_right.ReactModal__Content--before-close {
  transform: translateX(100%);
}
.slide-pane_from_left {
  margin-right: auto;
  transform: translateX(-100%);
}
.slide-pane_from_left.ReactModal__Content--after-open {
  transform: translateX(0%);
}
.slide-pane_from_left.ReactModal__Content--before-close {
  transform: translateX(-100%);
}
.slide-pane_from_bottom {
  height: 90vh;
  margin-top: 10vh;
  transform: translateY(100%);
}
.slide-pane_from_bottom.ReactModal__Content--after-open {
  transform: translateY(0%);
}
.slide-pane_from_bottom.ReactModal__Content--before-close {
  transform: translateY(100%);
}
.slide-pane__overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0);
}
.slide-over-overlay-side-nav {
  position: fixed;
  top: 56px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0);
}
.slide-pane__overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.5s;
}
.slide-pane__overlay.ReactModal__Overlay--before-close {
  background-color: rgba(0, 0, 0, 0);
}
.slide-pane__header {
  display: flex;
  flex: 0 0 64px;
  align-items: center;
  background: #f9f9fc;
  height: 64px;
  border-bottom: none;
  padding-top: 25px;
}
.slide-pane__title-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 17px;
  min-width: 0;
}
.slide-pane .slide-pane__title {
  font-size: 18px;
  font-weight: normal;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}
.slide-pane__close {
  margin-left: 16px;
  padding: 0;
  opacity: 0.7;
  cursor: pointer;
}
.slide-pane__close svg {
  width: 12px;
  padding: 0;
}
.slide-pane__content {
  position: relative;
  overflow-y: auto;
  padding: 16px 32px;
  flex: 1 1 auto;
}
.slide-pane__subtitle {
  font-size: 12px;
  margin-top: 2px;
}
.slide-pane .slide-pane__title {
  font-size: 32px;
  font-weight: 300;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}

.slide-pane__text-box {
  margin-top: 0px;
}

.slide-pane__text-box > .MuiInputBase-root {
  background-color: #fff;
}

// Dover Material UI multiselect overrides
// Necessary here because the popper with options is mounted outside of the
// parent element in the multiselect

.dover-multiselect-pq-popper
  > .MuiAutocomplete-paper
  > .MuiAutocomplete-listbox
  > .MuiAutocomplete-option[data-focus="true"] {
  background-color: #f5faff;
}
.dover-multiselect-pq-popper
  > .MuiAutocomplete-paper
  > .MuiAutocomplete-listbox
  > .MuiAutocomplete-option[aria-selected="true"] {
  background-color: #f5faff;
}

.custom-ddf-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  [type="submit"],
  button {
    background-color: #3ba384;
  }
}

// If the SVG uses path for color, this class will make it honor a color prop
.svg-color path {
  stroke: currentColor;
}

// If the SVG uses fill for color, this class will make it honor a color prop
.svg-fill path {
  fill: currentColor;
}

// This class will make the SVG have a pointer icon for the mouse on hover
.svg-pointer {
  cursor: pointer;
}

.MuiOutlinedInput-notchedOutline legend {
  width: unset;
}

// We can add this class to Containers or other wrappers of top-level pages to style them appropriately
// Ideally we eventually do this only on the top-level of the app, but this is a good start while we refresh the App UX
.top-level-page-container {
  padding-top: 20px;
}

// Apply this class to elements in order to visually hide scrollbars but maintain scroll behavior
.hide-scrollbar {
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/********** Animation Classes **********/
.translate-svg-right-on-hover {
  svg {
    transition: transform 0.1s ease-in-out;
  }
  &:hover {
    svg {
      transform: translateX(4px);
    }
  }
}

/********** Toast Style Overrides **********/
.Toastify {
  --error: #e06363;
  --toastify-icon-color-error: #e06363;
  --toastify-color-progress-error: #e06363;
  --success: #3ba384;
  --toastify-icon-color-success: #3ba384;
  --toastify-color-progress-success: #3ba384;
  --info: #39849e;
  --toastify-icon-color-info: #39849e;
  --toastify-color-progress-info: #39849e;
  --toastify-font-family: "Inter";
}
